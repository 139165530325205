import {Button, Card, CardGroup, Container, Header, Image, Input, Modal} from "semantic-ui-react";
import React, {useContext, useEffect, useState} from "react";
import {addPartner, getPartners, removePartner} from "../../utils/api";
import {UserContext} from "../../App";
import Gravatar from 'react-gravatar'


function Person({person, removePerson}) {
    const defaultImage = "https://react.semantic-ui.com/images/avatar/large/matthew.png"
    let name = "Unknown"
    let image = defaultImage
    if (typeof person === "string") {
        name = person
    } else {
        name = person.google_info.name
        image = person?.google_info?.picture || defaultImage
    }

    let img = <Image src={image}/>
    if (person.email !== undefined && person.email !== null) {
        img = <Gravatar email={person.email} size={290} default="mp" />
    }
    return <Card>
        {img}
        <Card.Content>
            <Card.Header>{name}</Card.Header>
        </Card.Content>
        <Card.Meta>
            <Button icon={"remove"} onClick={() => {
                removePerson(person)
            }}/>
        </Card.Meta>
    </Card>

}

function List() {
    let [loading, setLoading] = useState(true)
    let [partners, setPartners] = useState([])
    let [addEmail, setAddEmail] = useState("")
    let [showModal, setShowModal] = useState(false)
    const { accessToken } = useContext(UserContext);

    useEffect(() => {
        getPartners(accessToken).then(response => {
            setPartners(response.data)
            setLoading(false)
        })
    }, []);

    function removePerson(person) {
        let email = person.email
        if (typeof person === "string") {
            email = person
        }
        removePartner(accessToken, email).then(response => {
            getPartners(accessToken).then(response => {
                setPartners(response.data)
            })
        })
    }

    if (loading) {
        return (
            <Container>
                <Header as="h1">Loading...</Header>
            </Container>
        );
    }

    const addPartnerModal = <Modal
        trigger={<Button onClick={() => {setShowModal(true)}}>Add Partner</Button>}
        header='Add Partner'
        open={showModal}
    >
        <Modal.Header>Select a Photo</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Header>Type their email</Header>
                <Input type="email" label="email" value={addEmail} onChange={(e) => {
                    setAddEmail(e.target.value)
                }}/>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => {
                setAddEmail("")
                setShowModal(false)
            }}>
                Cancel
            </Button>
            <Button
                content="Add"
                labelPosition='right'
                icon='checkmark'
                onClick={() => {
                    addPartner(accessToken, addEmail).then(response => {
                        getPartners(accessToken).then(response => {
                            setPartners(response.data)
                        })
                    })
                    setAddEmail("")
                    setShowModal(false)
                }}
                Add Partner
            />
        </Modal.Actions>
    </Modal>

    if (partners.length === 0) {
        return (
            <Container>
                <Header as="h1">No partners found</Header>
                {addPartnerModal}
            </Container>
        );
    }

    return (
        <Container>
            <Header as="h1">Partners</Header>
            <CardGroup>
            {partners.map((partner, i) => {
                return <Person
                    person={partner}
                    key={"partner_" + i}
                    removePerson={removePerson}
                />
            })}
            </CardGroup>

            {addPartnerModal}
        </Container>
    );
}

export default List;